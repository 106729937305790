document.addEventListener("DOMContentLoaded", ()=> {
  const modal = document.getElementById("syncModal");

  const select_all = document.getElementById("select_all");
  if (select_all) {
    select_all.addEventListener("change", (e) => {
      document.querySelectorAll("input[id=courses_to_sync_]").forEach((element) => {
        element.checked = e.target.checked
      })
    })
  }

  const courses_checkboxes = document.querySelectorAll("input[id=courses_to_sync_], input[id=select_all]")
  courses_checkboxes.forEach((element) => {
    element.addEventListener("change", (e) => {
      const syncButton = document.getElementById("sync")
      if (document.querySelectorAll("input[id=courses_to_sync_]:checked").length > 0) {
        syncButton.disabled = false;
      } else {
        syncButton.disabled = true;
      }
    });

  });

  const paramsForSync = () => {
    const selectedCommunityId = document.getElementById("community_id")?.value;
    const selectedCatalogId = document.getElementById("catalog_id")?.value;
    const selectedCategory = document.getElementById("category_id");
    const selectedCategoryId = selectedCategory?.value;
    const selectedCategoryName = selectedCategory?.options[selectedCategory.selectedIndex].getAttribute('data-id') || null;

   const data = {};
    if (selectedCommunityId) data["community_id"] = selectedCommunityId;
    if (selectedCatalogId) data["catalog_id"] = selectedCatalogId;
    if (selectedCategoryId) data["category_id"] = selectedCategoryId;
    if (selectedCategoryId) data["category_name"] = selectedCategoryName;

    return data;
  }

  const syncButton = document.getElementById("sync");
  if (syncButton) {
    syncButton.onclick = () => {
      syncButton.disable = true;
      const token = document.querySelector('meta[name="csrf-token"]').content;
      const status = document.getElementById("status");
      const connectionType = document.getElementById("connection_type")?.value;
      const values = [];
      document.querySelectorAll("input[id=courses_to_sync_]:checked").forEach(item => values.push(item.value));
      const data = {
        courses_to_sync: values,
        ...paramsForSync()
      }
      fetch("/courses/sync", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "X-CSRF-Token": token
        },
        body: JSON.stringify(data)
      }).then(() => {
        status.innerHTML = `<strong>${values.length}</strong> ${pluralize(values.length)} will be synced to ${connectionType}</strong>.`;
        modal.style.display = "block"
      }).catch(() => {
        status.innerHTML = "There was an error while processing your request. Please try it again.";
        modal.style.display = "block"
      });
    }
  }

  const closeModalButton = document.getElementById("closeModalButton");
  if (closeModalButton) {
    closeModalButton.onclick = () => {
      modal.style.display = "none";
      window.location.reload();
    }
  }

  window.onclick = (event) => {
    if (event.target == modal) {
      modal.style.display = "none";
      window.location.reload();
    }
  }

  const pluralize = (amount) => amount && amount > 1 ? "courses" : "course";

  const catalogSelection = document.getElementById("catalog_id");
  const categorySelection = document.getElementById("category_id");

  const populateSelectionOptions = (event) => {
    const categories = catalogs[event.target.value]["categories"]
      categorySelection.innerHTML = "";
      categories.forEach((category) => {
        let newOption = document.createElement("option");
        newOption.value = category["Id"];
        newOption.innerHTML = category["Name"];
        newOption.setAttribute("data-id", category["Name"]);
        categorySelection.appendChild(newOption);
      })
  }

  if (catalogSelection && categorySelection) {
    catalogSelection.addEventListener("change", populateSelectionOptions);
  }
});
